import React, { useEffect } from 'react'
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import Close from '../icons/Close';
import { useNavigate } from 'react-router-dom';
import useAddressData from '../hooks/useAddressData';

function AddressAlert({ open, setOpen, orig, suggested, recipient }) {
    const navigate = useNavigate();
    const [address, setAddress] = useAddressData();

    function handleContinue() {
        const addrData = {
            customerId: orig.customerId,
            companyName: orig.companyName,
            fullName: orig.fullName,
            address1: suggested.streetLinesToken[0],
            address2: suggested.streetLinesToken[1] ? suggested.streetLinesToken[1] : null,
            city: suggested.city,
            state: suggested.stateOrProvinceCode,
            postalCode: suggested.postalCode,
            country: orig.country,
            phone: orig.phone,
            extension: orig.extension,
            email: orig.email,
            addressType: suggested.classification,
            recipient: recipient.recipContactName
        };

        setAddress(addrData);
        navigate('/package');
    }

    return (
        <AlertDialog.Root open={open} onOpenChange={setOpen}>
            <AlertDialog.Portal>
                <AlertDialog.Overlay className="bg-black bg-opacity-60 fixed top-0 right-0 bottom-0 left-0 transition-opacity animate-fade-in" />
                <AlertDialog.Content className="bg-white rounded-lg flex flex-col justify-between shadow-xl fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[450px] md:w-[630px] max-h-[85vh] p-8 focus:outline-none">
                    {/* Close Button */}
                    <AlertDialog.Cancel asChild>
                        <button className="absolute top-0 right-0 m-5 p-0 hover:shadow-none"><Close width={32} height={32} /></button>
                    </AlertDialog.Cancel>
                    <div>
                        <AlertDialog.Title className="font-bold text-lg mb-1">Verify your address</AlertDialog.Title>
                        <AlertDialog.Description className="leading-relaxed">
                            FedEx has suggested a different address than the one you have entered. Please select whether you would like to continue or edit.
                        </AlertDialog.Description>
                    </div>
                    <div className='flex flex-col justify-between bg-white py-10 px-16 mb-7'>
                        <div className='flex flex-col md:flex-row justify-center gap-5 md:gap-10 before:content-[""] before:border-[1px] before:self-stretch'>
                            {/* Original Address */}
                            <div className='-order-1'>
                                <h2 className='font-bold text-lg mb-2'>Address Entered</h2>
                                <div>
                                    <p>{orig.address1} {orig.street2}</p>
                                    <p>{orig.city}, {orig.state}, {orig.postalCode}</p>
                                    <p>{orig.country}</p>
                                </div>
                            </div>

                            {/* Suggested Address */}
                            <div>
                                <h2 className='font-bold text-lg mb-2'>Address Suggested</h2>
                                <div>
                                    <p>{suggested.streetLinesToken[0]} {suggested.streetLinesToken[1] ? suggested.streetLinesToken[1] : undefined}</p>
                                    <p>{suggested.city}, {suggested.stateOrProvinceCode}, {suggested.postalCode}</p>
                                    <p>{orig.country}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className='flex font-bold justify-center md:justify-end'>
                        <AlertDialog.Action asChild >
                            <button className="button mr-2" onClick={() => handleContinue()}>Use Suggested Address</button>
                        </AlertDialog.Action>
                        <AlertDialog.Action asChild>
                            <button className="button ml-2">Modify Address</button>
                        </AlertDialog.Action>
                    </div>
                </AlertDialog.Content>
            </AlertDialog.Portal>
        </AlertDialog.Root>
    )
}

export default AddressAlert
