import * as TooltipPrimitive from '@radix-ui/react-tooltip'

export default function Tooltip({children, content, open, defaultOpen, onOpenChange, ...props}) {
    return (
        <TooltipPrimitive.Provider delayDuration={500} skipDelayDuration={500}>
            <TooltipPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
                <TooltipPrimitive.Trigger asChild>
                    {children}
                </TooltipPrimitive.Trigger>
                <TooltipPrimitive.Content side='top' align='center' className='bg-white border-2 rounded-md max-w-xs text-sm p-2' {...props}>
                    {content}
                    <TooltipPrimitive.Arrow width={11} height={5} className='fill-gray-500'/>
                </TooltipPrimitive.Content>
                
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
  )
}
