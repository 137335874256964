export function addDays(date, val) {
    date.setDate(date.getDate() + val)
    return date;
}

export function subDays(date, val) {
    date.setDate(date.getDate() - val)
    return date;
}

export function longFormat(date) {
    const deliveryDate = new Date(date);
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dateString = weekday[deliveryDate.getDay()] + ", " + deliveryDate.toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' });

    return dateString;
}

export function longFormatNoTime(date) {
    const deliveryDate = new Date(date);
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dateString = weekday[deliveryDate.getDay()] + ", " + deliveryDate.toLocaleDateString('en-US', { dateStyle: 'medium' });

    return dateString;
}

export function apiFormat(date) {
    const formattedDate = new Date(date);
    return formattedDate.toISOString().substring(0, 10)
}