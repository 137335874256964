import React from 'react'
import Facebook from '../icons/Facebook'
import Twitter from '../icons/Twitter'
import LinkedIn from '../icons/LinkedIn'
import YouTube from '../icons/YouTube'

function Footer() {
    return (
        <div className='w-full h-20 px-10 flex flex-col justify-center items-center'>
            <div className='flex items-center [&>*:nth-child(1n+1)]:mx-1'>
                <a href="https://www.facebook.com/MiraVistaVets/" target="_blank" rel="noreferrer">
                    <Facebook width={28} height={28} />
                </a>
                <a href="https://twitter.com/MiraVistaVets" target="_blank" rel="noreferrer">
                    <Twitter width={28} height={28} />
                </a>
                <a href="https://www.youtube.com/channel/UCZupySbcNCYdPzYDSC_57QQ" target="_blank" rel="noreferrer">
                    <YouTube width={28} height={28} />
                </a>
                <a href="https://www.linkedin.com/company/miravistavets/" target="_blank" rel="noreferrer">
                    <LinkedIn width={28} height={28} />
                </a>
            </div>
            <p className='text-xs mt-3'>© 2023 MiraVista Diagnostics. All Rights Reserved.</p>
        </div>
    )
}

export default Footer