import * as AlertDialog from '@radix-ui/react-alert-dialog';
import Loading from './Loading';

function CreatingLabelAlert({ open, setOpen }) {

    return (
        <AlertDialog.Root open={open} onOpenChange={setOpen}>
            <AlertDialog.Portal>
                <AlertDialog.Overlay className="bg-black bg-opacity-60 fixed top-0 right-0 bottom-0 left-0 transition-opacity animate-fade-in" />
                <AlertDialog.Content className="bg-white rounded-lg flex flex-col justify-between shadow-xl fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80vw] max-w-xl max-h-[85vh] p-8 focus:outline-none">
                    <div>
                        <AlertDialog.Title className="font-bold text-lg mb-1">Creating your label...</AlertDialog.Title>
                        <AlertDialog.Description className="leading-relaxed">
                            Please wait a moment while your label is created.
                        </AlertDialog.Description>
                    </div>
                    <div className='flex bg-white py-10 px-16 justify-center items-center'>
                        <Loading className=' ' />
                    </div>
                </AlertDialog.Content>
            </AlertDialog.Portal>
        </AlertDialog.Root>
    )
}

export default CreatingLabelAlert
