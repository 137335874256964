import { useEffect, useState } from 'react'
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import Close from '../icons/Close';
import useError from '../hooks/useError';
import * as Accordion from '@radix-ui/react-accordion';
import ChevronDown from '../icons/ChevronDown';

function ErrorAlert() {
    const [open, setOpen] = useState(true);
    const { error, errorObj, clearError, orig } = useError();

    useEffect(() => {
        error ? setOpen(true) : setOpen(false);
    }, [error, errorObj])

    if (!error) return null;

    return (
        <AlertDialog.Root open={open} onOpenChange={setOpen}>
            {/* <AlertDialog.Trigger /> */}
            <AlertDialog.Portal>
                <AlertDialog.Overlay className="bg-black bg-opacity-60 fixed top-0 right-0 bottom-0 left-0 transition-opacity animate-fade-in" />
                <AlertDialog.Content className="bg-white rounded-lg flex flex-col justify-between shadow-xl fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80vw] max-w-5xl max-h-[85vh] p-8 focus:outline-none">
                    {/* Close Button */}
                    <AlertDialog.Cancel asChild>
                        <button className="absolute top-0 right-0 m-5 p-0 hover:shadow-none"><Close width={32} height={32} /></button>
                    </AlertDialog.Cancel>
                    <div>
                        <AlertDialog.Title className="font-bold text-2xl mb-1">Error</AlertDialog.Title>
                    </div>
                    <div className='flex flex-col justify-between bg-white py-4 xl:py-10 xl:px-16 gap-y-5'>
                        <div>
                            <h2 className='font-bold text-lg'>Message:</h2>
                            <div className=''>{error}</div>
                        </div>
                        <div>
                            <h3 className='font-bold text-lg'>Origin:</h3>
                            <p>{orig}</p>
                        </div>
                        {errorObj && <MoreDetails errorObj={errorObj} />}
                    </div>

                    {/* Action Buttons */}
                    <div className='flex font-bold justify-end'>
                        <AlertDialog.Action asChild onClick={() => clearError()}>
                            <button className="button mr-2">Close</button>
                        </AlertDialog.Action>
                    </div>
                </AlertDialog.Content>
            </AlertDialog.Portal>
        </AlertDialog.Root>
    )
}

function MoreDetails({ errorObj }) {
    return (
        <Accordion.Root collapsible>
            <Accordion.Item value='error'>
                <Accordion.Trigger className='flex items-center gap-x-2 bg-none w-full h-fit p-0 border-none hover:shadow-none text-neutral-900 text-left mb-4 group'>
                    <p className='font-semibold'>More details</p>
                    <ChevronDown className='w-4 h-4 ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180' />
                </Accordion.Trigger>
                <Accordion.Content className='animate-slideDown data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden'>
                    <div>
                        <pre className='h-32 min-h-[8rem] max-h-[500px] w-full px-4 py-2 bg-neutral-100 rounded-md overflow-scroll resize-y'>
                            <code className='w-full text-sm'>{errorObj}</code>
                        </pre>
                    </div>
                </Accordion.Content>
            </Accordion.Item>
        </Accordion.Root>
    )
}

export default ErrorAlert