import React from 'react'
import decimalToFraction from '../scripts/DecimalToFraction';

function LWH({ length, width, height, units }) {
    let fracLength = decimalToFraction(length);
    let fracWidth = decimalToFraction(width);
    let fracHeight = decimalToFraction(height);

    return (
        <>
            {/* Length */}
            {fracLength.split(' ')[0]}
            {fracLength.split(' ')[1] ?
                <span className='text-xs'>
                    <sup>{fracLength.split(' ')[1].split('/')[0]}</sup>
                    /
                    <sub>{fracLength.split(' ')[1].split('/')[1]}</sub>
                </span>
                : undefined}
            {/* Units + divider */}

            {units && <span className='text-xs ml-1'>{units}</span>}

            <span className='text-xs mx-2'>x</span>


            {/* Width */}
            {fracWidth.split(' ')[0]}
            {fracWidth.split(' ')[1] ?
                <span className='text-xs'>
                    <sup>{fracWidth.split(' ')[1].split('/')[0]}</sup>
                    /
                    <sub>{fracWidth.split(' ')[1].split('/')[1]}</sub>
                </span>
                : undefined}
            {/* Units + divider */}
            {units && <span className='text-xs ml-1'>{units}</span>}

            {height &&
                <>
                    <span className='text-xs mx-2'>x</span>

                    {/* Height */}
                    {fracHeight.split(' ')[0]}
                    {fracHeight.split(' ')[1] ?
                        <span className='text-xs'>
                            <sup>{fracHeight.split(' ')[1].split('/')[0]}</sup>
                            /
                            <sub>{fracHeight.split(' ')[1].split('/')[1]}</sub>
                        </span>
                        : undefined}
                    {/* Units */}
                    {units && <span className='text-xs ml-1'>{units}</span>}
                </>
            }
        </>
    )
}

export default LWH