/* eslint-disable react/prop-types */
import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import InfoHeader from './components/InfoHeader'
// import Chatbot from './components/Chatbot'

function Layout({ children }) {
    return (
        <>
            <Header />

            {/* 
                Below calculations subtract height of header and footer to make sure the height of the body fills the whole screen at a minimum.
                If those values change, these values need to be modified
            */}
            <div className='min-h-[calc(100vh-4rem-5rem)]'>
                <InfoHeader />
                <div className='w-full flex flex-col items-center min-h-[calc(100vh-4rem-5rem-14rem)]'>
                    <div className='flex flex-col max-w-screen-xl w-full h-full p-10 flex-grow'>
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default Layout