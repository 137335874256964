import { createContext, useState } from 'react';
import axios from 'axios';
import { captureException, captureMessage } from '@sentry/react';

const ErrorContext = createContext();

// Creating a Provider component to use this Context
export const ErrorProvider = ({ children }) => {
    const [error, setError] = useState(null);
    const [errorObj, setErrorObj] = useState(null);
    const [orig, setOrig] = useState(null);

    // Set an error
    const setErrorMessage = (err, orig) => {
        captureException(err);
        captureMessage(err);
        console.log(err);
        console.log(err.response);
        setOrig(orig);
        if (err.isAxiosError) {
            setErrorObj(JSON.stringify(err.toJSON(), undefined, 2));
            if (err.response) {
                console.log(err.toJSON());
                console.log(err.response);
                switch (err.response.status) {
                    case 400:
                        setError("A bad request was sent to the server.");
                        setErrorObj(JSON.stringify(err.response.data.error, undefined, 2));
                        break;
                    case 401:
                        setError("Your access token has expired. Please refresh your page and try again.");
                        break;
                    case 404:
                        setError("The endpoint to retrieve the data is unavailable.");
                        break;
                    case 422:
                        for (let i in err.response.data.error.errors) {
                            Array.isArray(err) ? setError([...error, <p key={i}>{`${i}: ${err.response.data.error.errors[i].code}`}</p>]) : setError([<p key={i}>{`${i}: ${err.response.data.error.errors[i].code}`}</p>]);
                            setErrorObj(JSON.stringify({ ...errorObj, [i]: err.response.data.error.errors[i] }, undefined, 2));
                        }
                        break;
                    case 500:
                        console.log(err.response.data);
                        if (err.response.data.errorFrom.toUpperCase() === "FedEx") {
                            setError("An unexpected error has occured.");
                            setErrorObj(JSON.stringify(err.response.data, undefined, 2));
                        }
                        else {
                            setError("Unable to contact the FedEx servers. Please try again in a few minutes.");
                        }
                        break;
                    case 503:
                        setError("Unable to contact the FedEx servers due to a service outage. This may be due to planned maintenance.");
                        break;
                    default:
                        setError("Unable to retrieve the necessary data from the server.");
                        break;
                }
            }
            else if (err.request) {
                console.log(err.request);
                // The request was made but no response was received
                setError("Unable to contact the MiraVista API server. Please try again momentarily. If you proceed to get this error, check your connection.");
            }
            else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', err.message);
            }
        }
        else if (err instanceof TypeError) {
            setError(err.message);
            setErrorObj(err.stack);
        }
        else {
            console.error(err);
            setError(err);
        }

    };

    // Clear the error
    const clearError = () => {
        setError(null);
        setErrorObj(null);
    };

    return (
        <ErrorContext.Provider value={{ error, orig, errorObj, setErrorMessage, setErrorObj, clearError }}>
            {children}
        </ErrorContext.Provider>
    );
};

export default ErrorContext;