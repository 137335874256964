import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useQuoteData from '../hooks/useQuoteData';
import useAddressData from '../hooks/useAddressData';
import { apiFormat, longFormat, longFormatNoTime } from '../scripts/DateManipulation';
import getBoxName from '../scripts/GetBoxName';
import axios from 'axios';
import useShipmentData from '../hooks/useShipmentData';
import CreatingLabelAlert from '../components/CreatingLabelAlert';
import useError from '../hooks/useError';
import ErrorAlert from '../components/ErrorAlert';
import * as Sentry from '@sentry/react';

function Submit() {
    const navigate = useNavigate();
    const [quoteData] = useQuoteData();
    const [addressData] = useAddressData();
    const [, setShipmentData] = useShipmentData();

    const [quote] = useState(quoteData);
    const [address] = useState(addressData);
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);

    const { setErrorMessage } = useError();

    const [displayData, setDisplayData] = useState({
        method: null,
        pickupMethod: null,
        shipmentDate: null,
        estDeliveryDate: null,
        box: null,
        weight: null,
        declaredValue: null,
        rate: null,
    });

    // Used for Declared Value and Estimated Rate
    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        // Estimated Delivery Date
        let delDate = new Date(quote.commit.dateDetail.dayFormat);
        const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dateString = weekday[delDate.getDay()] + ", " + delDate.toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' });

        const boxTypes = { FEDEX_SMALL_BOX: "FedEx Small Box", FEDEX_MEDIUM_BOX: "FedEx Medium Box", FEDEX_PAK: "FedEx Pak", YOUR_PACKAGING: "Your Packaging" }

        setDisplayData({
            ...displayData,
            method: quote.serviceName,
            pickupMethod: quote.pickupType,
            shipmentDate: quote.shipmentDate,
            estDeliveryDate: dateString,
            box: boxTypes[quote.packagingType],
            weight: quote.weight,
            declaredValue: quote.declaredValue,
            rate: quote.ratedShipmentDetails[0].totalNetFedExCharge
        })
    }, [quote])

    // Set Alert state based on loading state
    useEffect(() => {
        loading ? setAlertOpen(true) : setAlertOpen(false)
    }, [loading])


    function handleSubmit() {
        createShipment();


        async function createShipment() {
            setLoading(true);

            const payload = {
                "shipmentData": {
                    "requestedShipment": {
                        "shipper": {
                            "contact": {
                                "personName": address.fullName,
                                "phoneNumber": address.phone,
                                "companyName": address.companyName
                            },
                            "address": {
                                "streetLines": [
                                    address.address1,
                                    (address.address2) && address.address2
                                ],
                                "city": address.city,
                                "stateOrProvinceCode": address.state,
                                "postalCode": address.postalCode
                            }
                        },
                        "recipients": [
                            {
                                "contact": {
                                    "personName": address.recipient
                                }
                            }
                        ],
                        "shipDatestamp": apiFormat(quote.shipmentDate),
                        "serviceType": quote.serviceType,
                        "pickupType": quote.pickupMethod.code,
                        "packagingType": quote.packagingType,
                        "emailNotificationDetail": {
                            "emailNotificationRecipients": {
                                "name": address.fullName,
                                "emailAddress": address.email
                            }
                        },
                        "requestedPackageLineItems": [
                            {
                                "weight": {
                                    "value": quote.weight.toFixed(1)
                                },
                                "customerReferences": [
                                    {
                                        "value": address.customerId // CUSTOMER_REFERENCE
                                    },
                                    {
                                        "value": address.address1 // INVOICE_NUMBER...?
                                    },
                                    {
                                        "value": address.phone // P_O_NUMBER...?
                                    }
                                ]
                            }
                        ]
                    }
                }
            }

            console.log(payload);

            axios.post(`${import.meta.env.VITE_BASE_API_URL}/shipments`, payload)
                .then((res) => {
                    console.log(res);
                    setShipmentData(res.data.token.output.transactionShipments[0]);
                    navigate('/success');
                })
                .catch((error) => {
                    console.error(error);
                    setErrorMessage(error, "Submit");
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    return (
        <>
            <ErrorAlert />
            <div className='flex flex-col gap-y-10'>
                <CreatingLabelAlert open={alertOpen} setOpen={setAlertOpen} />
                <div>
                    <h1 className='text-xl font-semibold mb-2'>Please confirm your shipment information.</h1>
                    <p className='text-md'>Click <span className='font-semibold text-orange-600'>Create Shipping Order</span> to place a shipping order and print the label.</p>
                </div>

                <div className='flex w-full justify-evenly flex-wrap gap-10'>
                    {/* Sender */}
                    <div className='flex flex-col divider before:mb-2 flex-grow'>
                        <h2 className='font-semibold text-lg -order-1'>Sender</h2>
                        <table>
                            <tbody className='table [&>tr>td:nth-child(2)]:pl-5'>
                                <tr><td className='font-semibold'>Customer ID:</td><td className=''>{address.customerId}</td></tr>
                                <tr><td className='font-semibold'>Name:</td><td className=''>{address.fullName}</td></tr>
                                <tr><td className='font-semibold'>Organization:</td><td className=''>{address.companyName}</td></tr>
                                <tr><td className='font-semibold'>Street 1:</td><td className=''>{address.address1}</td></tr>
                                <tr><td className='font-semibold'>Street 2:</td><td className=''>{!address.address2 ? "N/A" : address.address2}</td></tr>
                                <tr><td className='font-semibold'>City:</td><td className=''>{address.city}</td></tr>
                                <tr><td className='font-semibold'>State:</td><td className=''>{address.state}</td></tr>
                                <tr><td className='font-semibold'>Postal Code:</td><td className=''>{address.postalCode}</td></tr>
                                <tr><td className='font-semibold'>Country:</td><td className=''>{address.country}</td></tr>
                                <tr><td className='font-semibold'>Phone:</td><td className=''>{address.phone}{address.extension && " Ext. " + address.extension}</td></tr>
                                <tr><td className='font-semibold'>Email:</td><td className=''>{address.email}</td></tr>
                            </tbody>
                        </table>
                    </div>

                    {/* Recipient */}
                    <div className='flex flex-col divider before:mb-2 flex-grow'>
                        <h2 className='font-semibold text-lg -order-1'>Recipient</h2>
                        <table className=''>
                            <tbody className='table [&>tr>td:nth-child(2)]:pl-5'>
                                <tr><td className='font-semibold'>Name:</td><td className=''>{address.recipient}</td></tr>
                                <tr><td className='font-semibold'>Organization:</td><td className=''>{"MiraVista Diagnostics"}</td></tr>
                                <tr><td className='font-semibold'>Street 1:</td><td className=''>{"4705 Decatur Blvd"}</td></tr>
                                <tr><td className='font-semibold'>Street 2:</td><td className=''>{"N/A"}</td></tr>
                                <tr><td className='font-semibold'>City:</td><td className=''>{"Indianapolis"}</td></tr>
                                <tr><td className='font-semibold'>State:</td><td className=''>{"IN"}</td></tr>
                                <tr><td className='font-semibold'>Postal Code:</td><td className=''>{"46241-9603"}</td></tr>
                                <tr><td className='font-semibold'>Country:</td><td className=''>{"US"}</td></tr>
                                <tr><td className='font-semibold'>Phone:</td><td className=''>{"3178562681"}</td></tr>
                                <tr><td className='font-semibold'>Email:</td><td className=''>{"info@miravistalabs.com"}</td></tr>
                            </tbody>
                        </table>
                    </div>

                    {/* Address */}
                    <div className='flex flex-col divider before:mb-2 flex-grow'>
                        <h2 className='font-semibold text-lg -order-1'>Address</h2>
                        <table className=''>
                            <tbody className='table [&>tr>td:nth-child(2)]:pl-5'>
                                <tr><td className='font-semibold'>Shipping Method:</td><td className=''>{quote.serviceName}</td></tr>
                                <tr><td className='font-semibold'>Pickup Method:</td><td className=''>{quote.pickupMethod.name}</td></tr>
                                <tr><td className='font-semibold'>Ship Date:</td><td className=''>{longFormatNoTime(quote.shipmentDate)}</td></tr>
                                <tr><td className='font-semibold'>Estimated Delivery Date:</td><td className=''>{longFormat(quote.operationalDetail?.deliveryDate)}</td></tr>
                                <tr><td className='font-semibold'>Packaging: </td><td className=''>{getBoxName(quote.packagingType)}</td></tr>
                                <tr><td className='font-semibold'>Package Weight (lbs): </td><td className=''>{quote.weight.toFixed(1)}</td></tr>
                                <tr><td className='font-semibold'>Declared Value: </td><td className=''>{USDollar.format(quote.declaredValue)}</td></tr>
                                <tr><td className='font-semibold'>Estimated Rate: </td><td className=''>{USDollar.format(quote.ratedShipmentDetails[0].totalNetFedExCharge)}</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Buttons */}
                <div className='flex self-end gap-x-2'>
                    <button type='button' className={`self-end button`} onClick={() => navigate('/address')}>Modify Shipment</button>
                    <button type='submit' className={`self-end button`} onClick={() => handleSubmit()}>Create Shipping Order</button>
                </div>
            </div>
        </>
    )
}

const SubmitWithProfiler = Sentry.withProfiler(Submit);
export default SubmitWithProfiler