function gcd(a, b) {
    // Function to get the Greatest Common Divisor
    return b ? gcd(b, a % b) : a;
}

function decimalToFraction(decimal, options = { returnType: "string" }) {
    // Step 1: Identify the whole number part
    const wholeNumber = Math.floor(decimal);
    if (decimal === wholeNumber) return decimal.toString();

    // Step 2: Get the decimal part
    const decimalPart = decimal - wholeNumber;

    // Step 3: Convert the decimal part to a fraction
    const precision = 1000000; // the larger this value, the more precise the fraction can be
    let numerator = Math.round(decimalPart * precision);
    let denominator = precision;

    // Step 4: Simplify the fraction
    const commonDivisor = gcd(numerator, denominator);
    numerator /= commonDivisor;
    denominator /= commonDivisor;

    if (wholeNumber === 0) {
        return `${numerator}/${denominator}`;
    } else {
        return `${wholeNumber} ${numerator}/${denominator}`;
    }
}

export default decimalToFraction;