import useError from "./useError";

export default function useQuoteData() {
    const { setErrorMessage } = useError();

    function setQuote(data) {
        try {
            sessionStorage.setItem("quote", JSON.stringify(data));
        }
        catch (error) {
            setErrorMessage(error, "useQuoteData");
        }
    }

    function quote() {
        try {
            return JSON.parse(sessionStorage.getItem("quote"));
        }
        catch (error) {
            setErrorMessage(error, "useQuoteData");
        }
    }

    return [quote(), setQuote];
}
