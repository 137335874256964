import React from 'react'
import Loading from './Loading'

function ShippingDetails({ data }) {
    const name = data.serviceName;

    // Date
    const deliveryDate = new Date(data.operationalDetail.deliveryDate);
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dateString = weekday[deliveryDate.getDay()] + ", " + deliveryDate.toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' });

    const rate = data.ratedShipmentDetails[0].totalNetFedExCharge;
    const packageType = data.packagingType;
    let formattedPackageType;
    const weight = data.weight;

    switch (packageType) {
        case "FEDEX_SMALL_BOX":
            formattedPackageType = "FedEx Small Box";
            break;

        case "FEDEX_MEDIUM_BOX":
            formattedPackageType = "FedEx Medium Box";
            break;

        case "FEDEX_PAK":
            formattedPackageType = "FedEx Pak";
            break;

        case "YOUR_PACKAGING":
            formattedPackageType = "Your Packaging";
            break;

        default:
            break;
    }

    return (
        <div className='flex flex-col gap-y-5'>
            <div className='divider flex flex-col'>
                <h3 className='font-semibold text-xl -order-1 mb-2'>{name}</h3>
                <p><span className='font-semibold'>Estimated Delivery: </span>{dateString}</p>
                <p><span className='font-semibold'>Estimated Rate: </span>${rate}</p>
                <p><span className='font-semibold'>Package Type: </span>{formattedPackageType}</p>
                <p><span className='font-semibold'>Package Weight: </span>{weight} lbs</p>
            </div>

            <div>
                <h4 className='font-semibold text-md text-red-500 underline underline-offset-2'>NOTICE</h4>
                <ul className='list-disc pl-4'>
                    <li className='text-sm text-red-500'>Saturday delivery for FedEx Express includes a surcharge that is included in the rate shown, if applicable.</li>
                    <li className='text-sm text-red-500'>FedEx may charge an additional pickup fee for one-time pickups.</li>
                </ul>
            </div>
        </div>
    )
}

export default ShippingDetails