import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { capitalize } from 'lodash-es';
import LWH from '../components/LWH';
import * as Sentry from '@sentry/react';

function PackageDetails() {
    const location = useLocation();
    const [packageType] = useState(location.state?.packageType);
    const navigate = useNavigate();
    const [packageDetails, setPackageDetails] = useState({
        length: "",
        width: "",
        height: "",
        weight: "1.0",
        declaredValue: "1.00"
    });
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [errors, setErrors] = useState({
        length: {
            err: false,
            msg: "The minimum value must be 1 inch. If the value is less than 1, round up.",
            isEdited: false
        },
        width: {
            err: false,
            msg: "The minimum value must be 1 inch. If the value is less than 1, round up.",
            isEdited: false
        },
        height: {
            err: false,
            msg: "The minimum value must be 1 inch. If the value is less than 1, round up.",
            isEdited: false
        },
        weight: {
            err: false,
            msg: "The maximum allowed weight is 5.0 lbs.",
            isEdited: false
        },
        declaredValue: {
            err: false,
            msg: ""
        },
    })

    function handleInputChange(e, stateSetter) {
        const { name, value } = e.target;
        const pattern = e.target.pattern;

        if (pattern !== "") {
            const valid = e.target.validity.valid;
            if (valid) {
                stateSetter(prevState => ({ ...prevState, [name]: value }));
                setErrors(prevState => ({ ...prevState, [name]: { ...prevState[name], isEdited: true } }))
            }
        }
        else {
            stateSetter(prevState => ({ ...prevState, [name]: value }));
        }
    }

    const packageInfo = {
        "FEDEX_SMALL_BOX": {
            img: "/fedex_sm_box.png",
            "fullName": "FedEx Small Box",
            sizes: [
                {
                    "name": "s1",
                    "dimensions": {
                        "width": 10.875,
                        "length": 12.375,
                        "height": 1.5,
                        "units": "in"
                    }
                },
                {
                    "name": "s2",
                    "dimensions": {
                        "width": 8.75,
                        "length": 11.3125,
                        "height": 2.6875,
                        "units": "in"
                    }
                }
            ]
        },
        "FEDEX_MEDIUM_BOX": {
            img: "/fedex_md_box.png",
            fullName: "FedEx Medium Box",
            sizes: [
                {
                    "name": "m1",
                    "dimensions": {
                        "width": 11.5,
                        "length": 13.25,
                        "height": 2.375,
                        "units": "in"
                    }
                },
                {
                    "name": "m2",
                    "dimensions": {
                        "width": 9.0,
                        "length": 12.0,
                        "height": 5.0,
                        "units": "in"
                    }
                },
            ]
        },
        "FEDEX_PAK": {
            img: '/fedex_pak.png',
            fullName: "FedEx Pak",
            sizes: [
                {
                    "name": "small",
                    "dimensions": {
                        "width": 10.25,
                        "length": 12.75,
                        "units": "in"
                    }
                },
                {
                    "name": "large",
                    "dimensions": {
                        "width": 12.0,
                        "length": 15.5,
                        "units": "in"
                    }
                }
            ]
        },
        "YOUR_PACKAGING": {
            "img": "/your_packaging.png",
            "fullName": "Your Packaging"
        }
    }



    function hasNullOrEmptyValue(obj) {
        return Object.values(obj).some(val => val === null || val === undefined || val === '' || val == 0);
    }

    function handleSubmit(e) {
        e.preventDefault();

        let newDetails = { ...packageDetails };

        for (let i in newDetails) {
            let val = newDetails[i];

            if (/^[1-9]\d*\.$/.test(val)) {
                newDetails[i] = Number(val).toFixed(1);
            }
        }
        setPackageDetails(newDetails);

        const formData = new FormData(e.target);

        // Convert FormData type to an object that we can pass to the next page
        let formObj = {};
        for (let i of formData.keys()) {
            formObj[i] = formData.get(i);
        }
        formObj["package_type"] = packageType;
        if (packageType === "YOUR_PACKAGING") {
            formObj = { ...formObj, ...packageDetails }
        }
        else {
            formObj = { ...formObj, ...packageInfo[packageType].sizes[0].dimensions };
        }

        // navigate({
        //     pathname: '/shipping_method',
        //     search: `?${createSearchParams(formObj)}`
        // });

        navigate('/shipping_method', { state: { 'formData': formObj } });
    }

    useEffect(() => {
        validate();


        function validate() {
            let newErrors = { ...errors };

            for (let key in packageDetails) {
                switch (key) {
                    case "weight":
                        if (errors.weight.isEdited) {
                            Number(packageDetails.weight) > 5 ?
                                newErrors = { ...newErrors, weight: { ...newErrors.weight, err: true } } :
                                newErrors = { ...newErrors, weight: { ...newErrors.weight, err: false } }
                        }
                        break;
                    case "width":
                        if (errors.width.isEdited) {
                            Number(packageDetails.width) < 1 || Number.isNaN(Number(packageDetails.width)) ?
                                newErrors = { ...newErrors, width: { ...newErrors.width, err: true } } :
                                newErrors = { ...newErrors, width: { ...newErrors.width, err: false } }
                        }
                        break;
                    case "length":
                        if (errors.length.isEdited) {
                            Number(packageDetails.length) < 1 || Number.isNaN(Number(packageDetails.length)) ?
                                newErrors = { ...newErrors, length: { ...newErrors.length, err: true } } :
                                newErrors = { ...newErrors, length: { ...newErrors.length, err: false } }
                        }
                        break;
                    case "height":
                        if (errors.height.isEdited) {
                            Number(packageDetails.height) < 1 || Number.isNaN(Number(packageDetails.height)) ?
                                newErrors = { ...newErrors, height: { ...newErrors.height, err: true } } :
                                newErrors = { ...newErrors, height: { ...newErrors.height, err: false } }
                        }
                        break;
                    default:
                        break;
                }
            }

            setErrors(newErrors);

            const error = Object.values(newErrors).some(error => error.err === true);

            let nullOrEmpty;
            // Only YOUR_PACKAGING (bring your own package) needs to check for all values since the user will also enter the dimensions of their package
            if (packageType === "YOUR_PACKAGING") {
                nullOrEmpty = hasNullOrEmptyValue(packageDetails);
            }
            else {
                // Make a copy of the object and remove the values we dont need to check for if it is a predefined package
                const strippedObj = { ...packageDetails };
                delete strippedObj.height;
                delete strippedObj.width;
                delete strippedObj.length;
                nullOrEmpty = hasNullOrEmptyValue(strippedObj);
            }

            error !== true && nullOrEmpty !== true ? setSubmitDisabled(false) : setSubmitDisabled(true);
        }
    }, [packageDetails])


    return (
        <div className='flex flex-col gap-y-10'>
            <div>
                <h1 className='text-xl font-semibold mb-2'>Please enter the details of your selected package.</h1>
            </div>

            <div className='flex justify-center md:justify-start'>
                <form className='flex flex-col gap-y-10 ' onSubmit={(e) => handleSubmit(e)}>
                    <div className='flex flex-col md:max-[878px]:flex-row md:max-[878px]:justify-between min-[878px]:max-[1150px]:flex-col min-[1150px]:flex-row gap-x-20 gap-y-10'>
                        <div className='flex flex-col items-center w-fit'>
                            <img className='w-48' src={packageInfo[packageType].img} alt='Selected package' />
                            <h3 className='text-md -order-1 min-[1150px]:order-1'>{packageInfo[packageType].fullName}</h3>
                        </div>

                        <div className='flex flex-col min-[878px]:flex-row gap-x-20 gap-y-10'>
                            {/* Dimensions */}
                            <div className='flex flex-col divider before:mb-2 w-52'>
                                <h3 className='text-lg font-semibold -order-1'>Dimensions (inches)</h3>

                                {packageType !== "YOUR_PACKAGING" ?
                                    <div>
                                        {packageInfo[packageType].sizes.map((size, i) => {
                                            {/* Subtract 1 to exclude units */ }
                                            const dimCount = Object.keys(size.dimensions).length - 1;
                                            if (dimCount === 3) {
                                                let length = size.dimensions.length;
                                                let width = size.dimensions.width;
                                                let height = size.dimensions.height;
                                                let units = size.dimensions.units;
                                                return (
                                                    <p key={i} className='whitespace-nowrap'>
                                                        <span className='font-semibold'>{capitalize(size.name)}: </span>
                                                        <LWH length={length} width={width} height={height} units={units} />
                                                    </p>
                                                )
                                            }
                                            else {
                                                let length = size.dimensions.length;
                                                let width = size.dimensions.width;
                                                let units = size.dimensions.units;
                                                return (
                                                    <p key={i}>
                                                        <span className='font-semibold'>{capitalize(size.name)}: </span>
                                                        <LWH length={length} width={width} units={units} />
                                                    </p>
                                                )
                                            }
                                        })}
                                    </div>

                                    :

                                    /* Your Packaging */
                                    <div className='flex flex-col'>
                                        <label htmlFor='length'>Length <span className='text-red-500'>*</span></label>
                                        <input className='mb-3 border-[1px] rounded-md h-[2.2rem] py-0.5 px-[9px] border-[#b3b3b3]' type="text" name="length" id="length" pattern='^[0-9]*(\.[0-9]*)?$' value={packageDetails.length} onChange={(e) => handleInputChange(e, setPackageDetails)} />
                                        {errors.length.err && <p className={`w-fit font-bold text-xs text-red-500 bg-white mb-2`}>{errors.length.msg}</p>}
                                        <label htmlFor='width'>Width <span className='text-red-500'>*</span></label>
                                        <input className='mb-3 border-[1px] rounded-md h-[2.2rem] py-0.5 px-[9px] border-[#b3b3b3]' type="text" name="width" id="width" pattern='^[0-9]*(\.[0-9]*)?$' value={packageDetails.width} onChange={(e) => handleInputChange(e, setPackageDetails)} />
                                        {errors.width.err && <p className={`w-fit font-bold text-xs text-red-500 bg-white mb-2`}>{errors.width.msg}</p>}
                                        <label htmlFor='height'>Height <span className='text-red-500'>*</span></label>
                                        <input className='mb-3 border-[1px] rounded-md h-[2.2rem] py-0.5 px-[9px] border-[#b3b3b3]' type="text" name="height" id="height" pattern='^[0-9]*(\.[0-9]*)?$' value={packageDetails.height} onChange={(e) => handleInputChange(e, setPackageDetails)} />
                                        {errors.height.err && <p className={`w-fit font-bold text-xs text-red-500 bg-white mb-2`}>{errors.height.msg}</p>}
                                    </div>
                                }
                            </div>

                            {/* Weight */}
                            <div className='flex flex-col divider before:mb-2'>
                                <h3 className='text-lg font-semibold -order-1'>Weight (lbs) <span className='text-red-500'>*</span></h3>
                                <div>
                                    <input className='mb-3 border-[1px] rounded-md h-[2.2rem] py-0.5 px-[9px] border-[#b3b3b3]' type="text" name="weight" id="weight" pattern='^[0-9]*(\.[0-9]*)?$' value={packageDetails.weight} onChange={(e) => handleInputChange(e, setPackageDetails)} />
                                    {errors.weight.err && <p className={`w-fit font-bold text-xs text-red-500 bg-white mb-2`}>{errors.weight.msg}</p>}
                                </div>
                            </div>

                            {/* Declared Value */}
                            <div className='flex flex-col divider before:mb-2'>
                                <h3 className='text-lg font-semibold -order-1'>Declared Value (USD)</h3>
                                <div className='relative'>
                                    <input className='mb-3 pl-5 border-[1px] rounded-md h-[2.2rem] py-0.5 px-[9px] border-[#b3b3b3] disabled' type="text" name="declaredValue" id="declaredValue" pattern='^\d+(\.\d{2})?$' value={packageDetails.declaredValue} onChange={(e) => handleInputChange(e, setPackageDetails)} readOnly />
                                    <span className='absolute left-2 top-[0.32rem] text-neutral-400'>$</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end gap-x-2'>
                        <button type='button' className={`button`} onClick={() => navigate(-1)}>Back</button>
                        <button type='submit' className={`button ${submitDisabled && '!bg-neutral-400 hover:shadow-none !cursor-default'}`} disabled={submitDisabled}>Next</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

const PackageDetailsWithProfiler = Sentry.withProfiler(PackageDetails);
export default PackageDetailsWithProfiler;