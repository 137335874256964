import React from 'react'

function Logo({ className }) {
    return (
        <svg width="221" height="52" viewBox="0 0 221 52" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <mask id="mask0_1_7" className="[mask-type:luminance]" maskUnits="userSpaceOnUse" x="173" y="0" width="20" height="51">
                <path d="M173.779 3.44401C173.991 3.75101 174.154 3.99801 174.369 4.24601C178.307 8.89201 182.2 13.552 186.14 18.186C186.824 19.012 187.154 19.896 187.154 20.982C187.132 30.488 187.154 39.995 187.154 49.537V50.374H192.627V49.654C192.627 39.83 192.627 29.968 192.674 20.109C192.674 18.363 192.132 16.866 190.977 15.545C187.368 11.264 183.783 6.98301 180.126 2.69001C179.417 1.80501 178.639 0.932007 177.838 0.00100708C176.443 1.19001 175.1 2.31201 173.779 3.44401Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1_7)">
                <path d="M172.6 50.361L173.779 -0.436996L193.807 0.0230035L192.626 50.82L172.6 50.361Z" fill="url(#paint0_linear_1_7)" />
            </g>
            <mask id="mask1_1_7" className="[mask-type:luminance]" maskUnits="userSpaceOnUse" x="195" y="0" width="20" height="51">
                <path d="M209.869 0.860002C205.765 5.756 201.66 10.662 197.509 15.545C196.354 16.878 195.813 18.363 195.858 20.109C195.858 29.946 195.858 39.783 195.858 49.619V50.42H201.307V49.514C201.307 39.959 201.356 30.395 201.356 20.829C201.356 19.85 201.662 19.035 202.252 18.305C206.19 13.659 210.084 9.011 214.023 4.353C214.283 4.07 214.495 3.763 214.708 3.445C213.34 2.289 212.043 1.18 210.627 0.0240021C210.341 0.342002 210.082 0.590002 209.869 0.860002Z" fill="white" />
            </mask>
            <g mask="url(#mask1_1_7)">
                <path d="M194.654 50.374L195.857 -0.425003L215.885 0.034997L214.705 50.846L194.654 50.374Z" fill="url(#paint1_linear_1_7)" />
            </g>
            <mask id="mask2_1_7" className="[mask-type:luminance]" maskUnits="userSpaceOnUse" x="167" y="4" width="18" height="20">
                <path d="M167.811 8.43401C172.128 13.576 176.397 18.671 180.762 23.801C182.129 22.657 183.475 21.502 184.866 20.322C180.55 15.177 176.28 10.096 171.963 4.96501C170.523 6.14501 169.202 7.28901 167.811 8.43401Z" fill="white" />
            </mask>
            <g mask="url(#mask2_1_7)">
                <path d="M167.387 23.79L167.811 4.56302L185.289 4.97802L184.865 24.202L167.387 23.79Z" fill="url(#paint2_linear_1_7)" />
            </g>
            <mask id="mask3_1_7" className="[mask-type:luminance]" maskUnits="userSpaceOnUse" x="203" y="4" width="18" height="20">
                <path d="M203.596 20.322C205.034 21.512 206.403 22.657 207.746 23.801C212.062 18.647 216.355 13.564 220.674 8.43401C219.282 7.26601 217.961 6.14501 216.522 4.96501C212.206 10.107 207.911 15.203 203.596 20.322Z" fill="white" />
            </mask>
            <g mask="url(#mask3_1_7)">
                <path d="M203.17 23.79L203.596 4.56302L221.1 4.97802L220.674 24.202L203.17 23.79Z" fill="url(#paint3_linear_1_7)" />
            </g>
            <path d="M23.307 48.133C23.071 48.711 22.858 49.218 22.621 49.678C22.433 50.126 22.149 50.516 21.843 50.823C21.489 51.155 21.112 51.401 20.641 51.553C20.168 51.707 19.626 51.78 18.966 51.78C18.235 51.78 17.621 51.684 17.149 51.483C16.678 51.272 16.277 51.024 15.969 50.682C15.64 50.35 15.405 49.96 15.192 49.526C14.98 49.088 14.767 48.63 14.602 48.133L6.818 27.823C6.77 27.658 6.653 27.445 6.534 27.198C6.369 26.962 6.252 26.82 6.087 26.82C5.827 26.82 5.709 26.962 5.687 27.245C5.639 27.527 5.616 27.682 5.616 27.74L5.734 51.447H0V24.65C0 23.671 0.212 22.904 0.637 22.385C1.109 21.842 1.58 21.441 2.148 21.169C2.667 20.922 3.209 20.769 3.751 20.733C4.294 20.674 4.671 20.65 4.907 20.65C5.779 20.65 6.511 20.768 7.101 20.958C7.69 21.158 8.209 21.44 8.587 21.818C8.987 22.172 9.317 22.596 9.553 23.069C9.836 23.552 10.049 24.048 10.262 24.566L18.14 44.877C18.282 45.195 18.4 45.467 18.541 45.68C18.658 45.903 18.801 45.997 18.966 45.997C19.083 45.997 19.225 45.902 19.366 45.68C19.508 45.467 19.649 45.196 19.791 44.877L27.646 24.566C28 23.694 28.33 23.009 28.707 22.514C29.133 21.994 29.533 21.606 30.005 21.334C30.453 21.051 30.948 20.875 31.421 20.791C31.916 20.697 32.435 20.649 33 20.649C34.416 20.649 35.572 20.98 36.539 21.617C37.435 22.242 37.907 23.257 37.907 24.648V51.445H32.176L32.34 27.74C32.34 27.339 32.293 27.092 32.152 26.985C32.011 26.891 31.917 26.82 31.821 26.82C31.68 26.82 31.539 26.962 31.397 27.198C31.256 27.445 31.138 27.658 31.09 27.823L23.307 48.133Z" fill="#425460" />
            <path d="M40.077 21.005H45.173V25.051H40.077V21.005ZM40.077 28.4H45.173V51.447H40.077V28.4Z" fill="#425460" />
            <path d="M46.471 37.105C46.471 35.819 46.635 34.592 46.942 33.414C47.25 32.245 47.863 31.231 48.852 30.394C49.419 29.946 49.938 29.568 50.599 29.285C51.211 29.014 51.825 28.803 52.486 28.683C53.099 28.553 53.666 28.483 54.256 28.435C54.845 28.424 55.34 28.4 55.789 28.4H59.208V32.316H57.108C56.425 32.316 55.694 32.399 54.844 32.576C54.067 32.753 53.358 33.189 52.721 33.921C52.249 34.486 51.919 35.183 51.778 35.961C51.637 36.751 51.566 37.565 51.566 38.438V51.448H46.471V37.105Z" fill="#425460" />
            <path d="M72.087 28.4C73.055 28.4 73.975 28.566 74.824 28.872C75.673 29.191 76.38 29.627 76.876 30.182C77.371 30.677 77.772 31.362 78.079 32.293C78.41 33.201 78.574 34.31 78.574 35.619V47.566C78.574 47.978 78.527 48.474 78.41 49.051C78.314 49.629 78.056 50.124 77.654 50.527C77.253 50.939 76.758 51.187 76.169 51.294C75.579 51.4 75.036 51.447 74.47 51.447H63.478C62.841 51.447 62.181 51.4 61.402 51.304C60.672 51.222 59.986 50.892 59.303 50.301C58.854 49.912 58.453 49.441 58.195 48.909C57.934 48.366 57.722 47.801 57.581 47.211C57.439 46.646 57.369 46.078 57.345 45.524C57.323 44.981 57.299 44.473 57.299 44.048C57.299 43.022 57.416 42.02 57.628 41.041C57.864 40.062 58.384 39.2 59.256 38.434C59.986 37.797 60.718 37.409 61.473 37.289C62.205 37.16 62.866 37.101 63.479 37.101H73.48V35.473C73.48 35.131 73.433 34.765 73.363 34.4C73.316 34.022 73.104 33.657 72.82 33.315C72.466 32.866 72.065 32.607 71.641 32.489C71.193 32.36 70.745 32.312 70.367 32.312H58.997V28.4H72.087ZM65.011 40.997C64.823 40.997 64.539 41.032 64.161 41.103C63.76 41.197 63.406 41.41 63.124 41.798C63.053 41.903 62.935 42.14 62.77 42.482C62.628 42.836 62.582 43.425 62.582 44.275C62.582 44.605 62.605 45.054 62.677 45.574C62.747 46.093 62.96 46.517 63.267 46.835C63.432 47.012 63.644 47.166 63.997 47.318C64.28 47.461 64.587 47.531 64.893 47.531H71.994C72.159 47.531 72.324 47.519 72.536 47.519C72.749 47.496 72.96 47.414 73.15 47.258C73.338 47.116 73.409 46.976 73.432 46.822C73.479 46.643 73.479 46.479 73.479 46.313V40.995L65.011 40.997Z" fill="#425460" />
            <path d="M92.139 45.574C92.186 45.679 92.256 45.832 92.351 45.986C92.421 46.138 92.585 46.21 92.776 46.21C92.987 46.21 93.13 46.141 93.2 45.986C93.295 45.832 93.365 45.679 93.436 45.574L102.518 21.005H108.745L97.729 48.486C97.54 48.84 97.351 49.205 97.116 49.594C96.904 49.984 96.573 50.349 96.196 50.679C95.796 51.021 95.323 51.269 94.804 51.482C94.238 51.681 93.576 51.777 92.776 51.777C92.422 51.777 92.044 51.754 91.62 51.693C91.172 51.646 90.747 51.505 90.275 51.256C89.803 51.044 89.331 50.691 88.93 50.254C88.529 49.817 88.152 49.228 87.868 48.485L76.829 21.005H83.057L92.139 45.574Z" fill="#425460" />
            <path d="M106.999 21.005H112.094V25.051H106.999V21.005ZM106.999 28.4H112.094V51.447H106.999V28.4Z" fill="#425460" />
            <path d="M113.107 47.531H126.035C126.294 47.531 126.599 47.496 126.908 47.425C127.215 47.343 127.521 47.166 127.732 46.871C127.945 46.646 128.086 46.387 128.132 46.116C128.156 45.845 128.156 45.62 128.156 45.443C128.156 45.113 128.132 44.818 128.059 44.523C127.99 44.24 127.801 43.958 127.518 43.698C127.423 43.603 127.33 43.533 127.164 43.462C127.022 43.393 126.81 43.297 126.506 43.18C126.174 43.075 125.797 42.944 125.302 42.789C124.808 42.637 124.148 42.46 123.345 42.225L118.91 40.997C117.682 40.689 116.669 40.302 115.817 39.851C114.969 39.404 114.332 38.861 113.86 38.225C113.387 37.587 113.131 36.915 112.987 36.22C112.846 35.524 112.776 34.887 112.776 34.309C112.776 33.731 112.846 33.095 112.987 32.399C113.131 31.703 113.387 31.043 113.86 30.394C114.214 29.946 114.616 29.568 115.063 29.285C115.489 29.014 115.913 28.803 116.408 28.683C116.881 28.553 117.376 28.483 117.871 28.435C118.392 28.424 118.886 28.4 119.403 28.4H132.403V32.316H120.182C119.874 32.316 119.591 32.316 119.286 32.351C118.957 32.386 118.674 32.516 118.39 32.741C118.083 32.965 117.87 33.225 117.825 33.508C117.754 33.779 117.73 34.086 117.73 34.404C117.73 34.711 117.752 35.006 117.825 35.312C117.919 35.595 118.154 35.878 118.462 36.184C118.65 36.327 118.887 36.444 119.147 36.574C119.407 36.692 119.667 36.799 119.925 36.892C120.208 36.998 120.467 37.105 120.727 37.188C120.987 37.27 121.223 37.329 121.364 37.353L126.295 38.744C127.687 39.158 128.843 39.535 129.786 39.9C130.707 40.265 131.483 40.75 132.051 41.349C132.642 41.988 132.995 42.705 133.16 43.46C133.348 44.238 133.442 44.935 133.442 45.572C133.442 46.963 133.207 48.037 132.829 48.803C132.405 49.569 131.957 50.134 131.53 50.477C130.919 50.915 130.305 51.186 129.669 51.29C128.985 51.396 128.396 51.443 127.853 51.443H113.111V47.529H113.107V47.531Z" fill="#425460" />
            <path d="M144.059 51.447C142.477 51.447 141.25 51.304 140.307 51.034C139.411 50.751 138.608 50.327 137.876 49.748C137.1 49.135 136.557 48.393 136.131 47.484C135.777 46.588 135.567 45.396 135.567 43.922V32.316H132.407V28.4H135.567V21.004H140.684V28.4H147.079V32.316H140.684V41.563V42.873C140.684 43.31 140.684 43.746 140.754 44.183C140.85 44.609 140.966 45.034 141.154 45.469C141.342 45.894 141.627 46.236 142.005 46.531C142.596 47.025 143.277 47.32 144.059 47.392C144.882 47.486 145.473 47.534 145.968 47.534H147.218V51.45L144.059 51.447Z" fill="#425460" />
            <path d="M160.994 28.4C161.935 28.4 162.857 28.566 163.706 28.872C164.532 29.191 165.239 29.627 165.734 30.182C166.23 30.677 166.63 31.362 166.961 32.293C167.293 33.201 167.434 34.31 167.434 35.619V47.566C167.434 47.978 167.412 48.474 167.293 49.051C167.197 49.629 166.915 50.124 166.537 50.527C166.113 50.939 165.617 51.187 165.074 51.294C164.462 51.4 163.894 51.447 163.351 51.447H152.359C151.699 51.447 151.039 51.4 150.284 51.304C149.555 51.222 148.868 50.892 148.161 50.301C147.736 49.912 147.335 49.441 147.077 48.909C146.816 48.366 146.58 47.801 146.463 47.211C146.32 46.646 146.251 46.078 146.227 45.524C146.18 44.981 146.158 44.473 146.158 44.048C146.158 43.022 146.274 42.02 146.512 41.041C146.747 40.062 147.266 39.2 148.139 38.434C148.869 37.797 149.577 37.409 150.333 37.289C151.064 37.16 151.725 37.101 152.361 37.101H162.361V35.473C162.361 35.131 162.314 34.765 162.244 34.4C162.198 34.022 162.009 33.657 161.702 33.315C161.348 32.866 160.971 32.607 160.521 32.489C160.074 32.36 159.625 32.312 159.224 32.312H147.878V28.4H160.994ZM153.895 40.997C153.707 40.997 153.399 41.032 153.044 41.103C152.619 41.197 152.289 41.41 152.007 41.798C151.912 41.903 151.819 42.14 151.629 42.482C151.51 42.836 151.441 43.425 151.441 44.275C151.441 44.605 151.462 45.054 151.558 45.574C151.627 46.093 151.816 46.517 152.148 46.835C152.312 47.012 152.525 47.166 152.855 47.318C153.138 47.461 153.467 47.531 153.775 47.531H160.851C161.039 47.531 161.205 47.519 161.416 47.519C161.629 47.496 161.841 47.414 162.028 47.258C162.194 47.116 162.289 46.976 162.311 46.822C162.335 46.643 162.358 46.479 162.358 46.313V40.995L153.895 40.997Z" fill="#425460" />
            <defs>
                <linearGradient id="paint0_linear_1_7" x1="182.657" y1="49.3009" x2="183.4" y2="17.3398" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CC7228" />
                    <stop offset="1" stopColor="#F7972A" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_7" x1="204.718" y1="49.8208" x2="205.46" y2="17.8517" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CC7228" />
                    <stop offset="1" stopColor="#F7972A" />
                </linearGradient>
                <linearGradient id="paint2_linear_1_7" x1="175.514" y1="49.1719" x2="176.257" y2="17.1756" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CC7228" />
                    <stop offset="1" stopColor="#F7972A" />
                </linearGradient>
                <linearGradient id="paint3_linear_1_7" x1="211.31" y1="49.9885" x2="212.053" y2="18.0133" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CC7228" />
                    <stop offset="1" stopColor="#F7972A" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default Logo