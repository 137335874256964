import { CSSProperties, useState } from 'react'
import { SpinnerCircularFixed } from 'spinners-react'

function Loading({ className = "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2", color = "orange", secondaryColor = "#ddd", size, speed = 150 }) {
    const [thickness] = useState(150);

    return (
        <SpinnerCircularFixed className={`${className}`} size={size} color={color} secondaryColor={secondaryColor} speed={speed} thickness={thickness} />
    )
}

export default Loading