import { useMemo } from "react";
import useError from "./useError";

export default function useAddressData() {
    const { setErrorMessage } = useError();

    function setAddress(data) {
        try {
            sessionStorage.setItem("address", JSON.stringify(data));
        }
        catch (error) {
            setErrorMessage(error, "useAddressData");
        }
    }

    const address = useMemo(() => {
        try {
            return JSON.parse(sessionStorage.getItem("address"));
        }
        catch (error) {
            setErrorMessage(error, "useAddressData");
        }
    }, [sessionStorage.getItem("address")]);

    return [address, setAddress];
}
