import React from 'react'
import Close from '../icons/Close'
import ShippingBox from '../icons/ShippingBox';
import Phone from '../icons/Phone';

function Slideout({ open, setOpen }) {
    return (
        <div className={`${open ? 'fixed animate-slide-in' : 'hidden'} top-0 left-0 bottom-0 z-10 w-72 h-screen bg-white drop-shadow-md flex flex-col py-24 px-4`}>
            <div className='my-3 mx-4 absolute right-0 top-0 cursor-pointer' onClick={() => setOpen(false)}><Close width={48} height={48} className={'fill-[#425460]'} /></div>
            <div className='flex flex-col gap-y-6'>
                <a href='https://miravistavets.com/resources/order/' className='block' target="_blank" rel="noreferrer">
                    <div className='flex items-center hover:underline hover:underline-offset-2 decoration-orange-500'>
                        <ShippingBox className={"mr-2"} />
                        <p className='font-semibold text-lg text-orange-500 whitespace-nowrap'>Shipping Info</p>
                    </div>
                </a>
                <div className='flex'>
                    <Phone className={'mr-2'} />
                    <p className='font-semibold text-lg text-orange-500 whitespace-nowrap'>(866) 647-2847 (Option 1)</p>
                </div>
            </div>
        </div>
    )
}

export default Slideout