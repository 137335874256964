import React from 'react'

function InfoHeader() {
    return (
        <>
            {/* Header Info and Image */}
            <div className='w-full h-28 md:h-56 relative'>
                {/* Color Bars */}
                <div className='absolute h-full w-full -z-10 drop-shadow-md'>
                    <div className='w-full h-full md:h-1/2 bg-orange-500'></div>
                    <div className='w-full h-1/2 bg-white hidden md:block'></div>
                </div>
                {/* Content */}
                <div className='h-full flex justify-between px-20'>
                    {/* Col 1 */}
                    <div className='h-full w-1/2'>
                        {/* Row 1 */}
                        <div className='w-full h-full md:h-1/2 flex flex-col justify-center py-4'>
                            <h1 className='font-bold text-white text-2xl'>Discounted Shipping</h1>
                        </div>
                        {/* Row 2 */}
                        <div className='hidden md:flex w-full h-1/2 justify-start items-center py-4 before:content-[""] before:border-[1px] before:self-stretch gap-8'>
                            <img src='/miravet-diagnostics-logo.svg' alt="MiraVista Logo" className='-order-1 h-3/4' />
                            <img src='/fedex.png' alt='FedEx Logo' className='h-3/4' />
                        </div>
                    </div>
                    {/* Col 2 */}
                    <div className='flex items-center w-1/2 justify-end'>
                        <img src='/dog_header.png' alt="Dog" className='h-full md:h-56' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfoHeader