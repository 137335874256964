import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ErrorAlert from '../components/ErrorAlert';
import useError from '../hooks/useError';
import * as Sentry from '@sentry/react';

function Index() {
    const navigate = useNavigate();

    const [existingClient, setExistingClient] = useState(false);
    const [usClient, setUSClient] = useState(false);
    const { setErrorMessage } = useError();

    return (
        <>
            <ErrorAlert />
            <h3 className='text-lg'>MiraVista offers access to discounted shipping through FedEx. Please have your 8-digit customer ID  available. The ID number can be found in the top section of the last invoice. If your practice does not have an account or you need to verify your customer ID, please contact our laboratory support team at <span className='font-semibold'>866-647-2847 (option 1)</span>.</h3>

            <div className='spacer'></div>

            {/* Form */}
            <div className='w-full flex flex-col md:flex-row justify-between md:items-stretch [&>*:nth-child(1n+1)]:mb-6 gap-y-10 md:gap-y-0'>
                <div className='md:w-[45%] flex flex-col justify-between gap-y-20 md:gap-y-0'>
                    <div className='flex flex-col [&>*:nth-child(1n+1)]:mb-6'>
                        {/* Existing Client */}
                        <div className='flex flex-col'>
                            <h2 className='font-bold text-2xl mb-2'>Are you an existing client?</h2>
                            <div className='flex justify-start text-lg font-semibold [&>*:nth-child(1n+1)]:mr-5'>
                                <div>
                                    <input type="radio" name="client-yes_no" id="client-yes" onChange={() => setExistingClient(true)} />
                                    <label htmlFor="client-yes" className='mx-2'>Yes</label>
                                </div>
                                <div>
                                    <input type="radio" name="client-yes_no" id="client-no" onChange={() => setExistingClient(false)} />
                                    <label htmlFor="client-no" className='mx-2'>No</label>
                                </div>
                            </div>
                        </div>

                        {/* US Based */}
                        <div className='flex flex-col'>
                            <h2 className='font-bold text-2xl mb-2'>Is your business located in the US?</h2>
                            <div className='flex justify-start text-lg font-semibold [&>*:nth-child(1n+1)]:mr-5'>
                                <div>
                                    <input type="radio" name="us-based-yes_no" id="us-based-yes" onChange={() => setUSClient(true)} />
                                    <label htmlFor="us-based-yes" className='mx-2'>Yes</label>
                                </div>
                                <div>
                                    <input type="radio" name="us-based-yes_no" id="us-based-no" onChange={() => setUSClient(false)} />
                                    <label htmlFor="us-based-no" className='mx-2'>No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className={`self-end justify-self-end ${!(existingClient && usClient) ? "bg-neutral-500" : null}`}
                        onClick={() => navigate('/address')}
                        disabled={(existingClient && usClient) ? false : true}
                    >
                        Next
                    </button>
                </div>
                <div className='h-full md:w-[45%] flex flex-col'>
                    <div className=''>
                        {/* Terms of Use */}
                        <div className='border-orange-500 border-4 rounded-md p-5'>
                            <h3 className='text-2xl mb-5'>Terms of Use</h3>
                            <div className='[&>*:nth-child(1n+1)]:mb-2'>
                                <p>Discounted shipping is a benefit we offer our customers when submitting diagnostic specimens.</p>
                                <p>The shipping cost provided is an estimate based upon your location, the service selected, weight and dimensions entered. Actual shipping cost will be determined by FedEx and included in your monthly diagnostic services invoice. </p>
                                <p>Please contact us with questions at 866-647-2847, option 1.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const IndexWithProfiler = Sentry.withProfiler(Index)
export default IndexWithProfiler;