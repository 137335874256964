import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function DatePicker({ selected, setSelected, includeDates }) {

    return (
        <ReactDatePicker
            selected={selected}
            onChange={(date) => setSelected(date)}
            className='w-48 border-[1px] border-[#b3b3b3] rounded-md mb-3 h-[2.2rem] py-0.5 px-[9px]'
            includeDateIntervals={[{ start: includeDates.start, end: includeDates.end }]}
        />
    )
}

export default DatePicker