import { useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { capitalize } from 'lodash-es';
import Loading from '../components/Loading';
import LWH from '../components/LWH';
import * as Sentry from '@sentry/react';

function Package() {
    const navigate = useNavigate();
    const [descEles, setDescEles] = useState([]);
    const [packageSelection, setPackageSelection] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [smLoading, setSmLoading] = useState(true);
    const [mdLoading, setMdLoading] = useState(true);
    const [pakLoading, setPakLoading] = useState(true);
    const [ypLoading, setYpLoading] = useState(true);

    function handleSubmit(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        const selectedPackageType = formData.get('package_type');

        navigate('/package_details', { state: { 'packageType': selectedPackageType } });
    }

    useEffect(() => {
        packageSelection ? setSubmitDisabled(false) : setSubmitDisabled(true);
    }, [packageSelection])

    useEffect(() => {
        let tempEles = [];
        const packageInfo = {
            "FEDEX_SMALL_BOX": [
                {
                    "size": "s1",
                    "fullName": "FedEx Small Box",
                    "description": "Rectangular and shallow",
                    "dimensions": {
                        "width": 10.875,
                        "length": 12.375,
                        "height": 1.5,
                        "units": "in"
                    }
                },
                {
                    "size": "s2",
                    "fullName": "FedEx Small Box",
                    "description": "Rectangular and deep",
                    "dimensions": {
                        "width": 8.75,
                        "length": 11.3125,
                        "height": 2.6875,
                        "units": "in"
                    }
                }

            ],
            "FEDEX_MEDIUM_BOX": [
                {
                    "size": "m1",
                    "fullName": "FedEx Medium Box",
                    "description": "Rectangular and shallow",
                    "dimensions": {
                        "width": 11.5,
                        "length": 13.25,
                        "height": 2.375,
                        "units": "in"
                    }
                },
                {
                    "size": "m2",
                    "fullName": "FedEx Medium Box",
                    "description": "Rectangular and deep",
                    "dimensions": {
                        "width": 9.0,
                        "length": 12.0,
                        "height": 5.0,
                        "units": "in"
                    }
                }
            ],
            "FEDEX_PAK": [
                {
                    "size": "small",
                    "fullName": "FedEx Pak",
                    "dimensions": {
                        "width": 10.25,
                        "length": 12.75,
                        "units": "in"
                    }
                },
                {
                    "size": "large",
                    "fullName": "FedEx Pak",
                    "dimensions": {
                        "width": 12.0,
                        "length": 15.5,
                        "units": "in"
                    }
                }
            ]
        }

        for (let key in packageInfo) {
            const pType = packageInfo[key];

            // Set Dimension Elements
            const dimEles = pType.map((obj, i) => {
                return (
                    <div key={i} className='flex flex-col'>
                        <p>
                            <span className='font-semibold'>{capitalize(obj.size)}</span>: {
                                <LWH length={obj.dimensions.length} width={obj.dimensions.width} height={obj.dimensions.height} units={obj.dimensions.units} />
                            }
                        </p>
                    </div>
                )
            });

            // Set Description Elements
            const descTextEles = pType.map((obj, i) => {
                if (obj.description) {
                    return (
                        <div key={i} className='flex flex-col'>
                            <p>
                                <span className='font-semibold'>{capitalize(obj.size)}</span>: {
                                    obj.description
                                }
                            </p>
                        </div>
                    )
                }
            });


            const ele = (
                <div key={key} className='mb-8 grow'>
                    <div className='w-fit flex flex-col before:content-[""] before:border-[2px] before:rounded-sm before:mb-4'>
                        <h2 className='font-semibold text-xl -order-1 mb-2'>{pType[0].fullName}</h2>
                        <div className='flex flex-col gap-y-5'>
                            {/* Dimensions */}
                            <div className='flex flex-col before:content-[""] before:border-[1px] before:rounded-sm before:self-stretch before:mb-1'>
                                <h3 className='font-semibold text-md -order-1'>Dimensions</h3>
                                {dimEles}
                            </div>

                            {/* Description (if type is 'pak', skip because it has no description) */}
                            {key !== "FEDEX_PAK" ?
                                <div className='flex flex-col before:content-[""] before:border-[1px] before:rounded-sm before:self-stretch before:mb-1'>
                                    <h3 className='font-semibold text-md -order-1'>Description</h3>
                                    {descTextEles}
                                </div>
                                :
                                undefined}
                        </div>

                    </div>

                </div>);

            tempEles.push(ele);
        }

        setDescEles(tempEles);
    }, [packageSelection])

    return (
        <div className='flex flex-col gap-y-10'>
            <div>
                <h1 className='text-xl font-semibold mb-2'>Please select the package that you will be shipping your specimen in.</h1>
                <p className='text-md'>The smaller the package type, the lower your shipping cost will be. Try to choose the smallest package possible.</p>
            </div>

            <div className='flex flex-col min-[1000px]:flex-row min-[1000px]:justify-between divider before:border-2 gap-y-20'>
                <form className='flex flex-col gap-y-5 w-full min-[1000px]:w-2/3 -order-1' onChange={(e) => setPackageSelection(e.target.value)} onSubmit={(e) => handleSubmit(e)}>
                    <div className='w-fit relative'>
                        <div>
                            <input type="radio" name="package_type" id="small_box" className='mr-2' value={"FEDEX_SMALL_BOX"} />
                            <label htmlFor="small_box" className='text-md '>FedEx Small Box <span className='font-medium text-xs'>(S1, S2)</span></label>
                        </div>
                        {smLoading && <Loading />}
                        <img src='/fedex_sm_box.png' alt='FedEx Small box' className='h-32' onLoad={() => setSmLoading(false)} />
                    </div>
                    <div className='w-fit relative'>
                        <div>
                            <input type="radio" name="package_type" id="medium_box" className='mr-2' value={"FEDEX_MEDIUM_BOX"} />
                            <label htmlFor="medium_box" className='text-md '>FedEx Medium Box <span className='font-medium text-xs'>(M1, M2)</span></label>
                        </div>
                        {mdLoading && <Loading />}
                        <img src='/fedex_md_box.png' alt='FedEx Medium box' className='h-32' onLoad={() => setMdLoading(false)} />
                    </div>
                    <div className='w-fit relative'>
                        <div>
                            <input type="radio" name="package_type" id="pak" className='mr-2' value={"FEDEX_PAK"} />
                            <label htmlFor="pak" className='text-md '>FedEx Pak <span className='font-medium text-xs'>(Small, Large)</span></label>
                        </div>
                        {pakLoading && <Loading />}
                        <img src='/fedex_pak.png' alt='FedEx Pak package' className='h-32' onLoad={() => setPakLoading(false)} />
                    </div>
                    <div className='w-fit relative'>
                        <div>
                            <input type="radio" name="package_type" id="your_packaging" className='mr-2' value={"YOUR_PACKAGING"} />
                            <label htmlFor="your_packaging" className='text-md '>Your Packaging</label>
                        </div>
                        {ypLoading && <Loading />}
                        <img src='/your_packaging.png' alt='Your own box' className='h-32' onLoad={() => setYpLoading(false)} />
                    </div>
                    <div className='flex justify-end gap-x-2'>
                        <button type='button' className={`button`} onClick={() => navigate(-1)}>Back</button>
                        <button type='submit' className={`mt-auto self-end button w-[69px] h-[39px] mr-14 ${submitDisabled && '!bg-neutral-400 hover:shadow-none !cursor-default'}`} disabled={submitDisabled}>Next</button>
                    </div>
                </form>

                {/* Package Information */}
                <div className='w-full min-[1000px]:w-fit px-10 justify-center'>
                    <div className='flex flex-wrap min-[1000px]:flex-nowrap min-[1000px]:flex-col gap-x-20 w-fit'>
                        {descEles.length > 0 ? descEles : undefined}
                    </div>
                </div>
            </div>
        </div>
    )
}

const PackageWithProfiler = Sentry.withProfiler(Package)
export default PackageWithProfiler;