export default function getBoxName(boxId) {
    let formattedPackageType
    switch (boxId) {
        case "FEDEX_SMALL_BOX":
            formattedPackageType = "FedEx Small Box";
            break;

        case "FEDEX_MEDIUM_BOX":
            formattedPackageType = "FedEx Medium Box";
            break;

        case "FEDEX_PAK":
            formattedPackageType = "FedEx Pak";
            break;

        case "YOUR_PACKAGING":
            formattedPackageType = "Your Packaging";
            break;

        default:
            return null;
    }

    return formattedPackageType;
}

