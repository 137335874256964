import React from 'react'
import * as AlertDialogPrimative from '@radix-ui/react-alert-dialog';

export default function AlertDialog({children, open, setOpen, title, description, cancelButton, actionButton}) {
    return (
        <AlertDialogPrimative.Root open={open} onOpenChange={setOpen}>
            <AlertDialogPrimative.Portal>
                <AlertDialogPrimative.Overlay className="bg-black bg-opacity-60 fixed top-0 right-0 bottom-0 left-0 transition-opacity animate-fade-in" />
                <AlertDialogPrimative.Content className="bg-white gap-y-6 rounded-lg flex flex-col justify-between shadow-xl fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80vw] max-w-xl max-h-[85vh] p-8 focus:outline-none">
                    <div>
                        <AlertDialogPrimative.Title className="font-bold text-lg mb-1">{title}</AlertDialogPrimative.Title>
                        <AlertDialogPrimative.Description className="leading-relaxed">
                            {description}
                        </AlertDialogPrimative.Description>
                    </div>
                    {children}

                    {/* Action Buttons */}
                    <div className='flex font-bold justify-center md:justify-end'>
                        {
                            (cancelButton && cancelButton.enabled) &&
                            <AlertDialogPrimative.Cancel asChild>
                                <button className="button mr-2" onClick={cancelButton.action}>{cancelButton.text}</button>
                            </AlertDialogPrimative.Cancel>
                        }
                        {
                            (actionButton && actionButton.enabled) &&
                            <AlertDialogPrimative.Action asChild>
                                <button className="button ml-2" onClick={actionButton.action}>{actionButton.text}</button>
                            </AlertDialogPrimative.Action>
                        }
                    </div>
                </AlertDialogPrimative.Content>
            </AlertDialogPrimative.Portal>
        </AlertDialogPrimative.Root>
    )
}
