import React, { useEffect } from 'react'
import useShipmentData from '../hooks/useShipmentData'
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

function Success() {
    const [shipmentData] = useShipmentData();

    const navigate = useNavigate();

    useEffect(() => {
        window.open(shipmentData.pieceResponses[0].packageDocuments[0].url, '_blank');
    }, [])


    function handlePrint() {
        window.open(shipmentData.pieceResponses[0].packageDocuments[0].url, '_blank');
    }


    return (
        <div className='flex flex-col gap-y-8 w-full h-full flex-grow'>
            <h1 className='font-semibold text-2xl'>Your label has been created!</h1>
            <div className='flex flex-col gap-y-10'>
                <div>
                    <h2 className='flex flex-col w-fit font-bold text-lg -order-1 after:content-[""] after:border-[1px] after:border-neutral-300 after:mb-2'>Instructions</h2>
                    <div className='flex flex-col gap-y-6'>
                        <div>
                            <p>Please print your shipping label and affix it to your package.</p>
                            <p><span className='font-semibold text-orange-500'>Note: The link to this shipping label will expire after 24 hours.</span> Please make sure you save this label locally onto your computer if you need to access it later. If you do not save the label and are no longer able to access it later, you will need to create a new label.</p>
                            <p>If you selected the option to drop-off your package at a FedEx location, you can find the nearest location here: <a href='https://local.fedex.com/en-us' target="_blank" rel="noreferrer">https://local.fedex.com/en-us</a></p>
                        </div>
                        <div className='flex flex-col gap-y-1'>
                            <h3 className='font-bold'>Additional Notes</h3>
                            <div className='flex flex-col gap-y-2'>
                                <p>You will not be charged for the price of the label at the time of the drop-off. MiraVista will bill the shipping cost with your monthly invoice.</p>
                                <p>Please note that if you schedule a one-time pickup, FedEx may charge you a fee. Please reference <a href='https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html#cost' target="_blank" rel="noreferrer">this page</a> for more information.</p>
                                <p>If you need to cancel your shipment after dropping off the package, please contact our Laboratory Support team at (866) 647-2847, Option 1.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h2 className='flex flex-col w-fit font-bold text-lg -order-1 after:content-[""] after:border-[1px] after:border-neutral-300 after:mb-2'>Troubleshooting</h2>
                    <div className='flex flex-col gap-y-3'>
                        <p>If your label did not automatically open, and or download, please make sure you have allowed pop-ups for this website.</p>
                        <p>Alternatively, you can click the <span className='font-bold'>Print Label</span> button below to manually open the label.</p>
                    </div>
                </div>

                <div className='mt-auto self-end flex gap-x-2'>
                    <button onClick={() => navigate('/address')}>Create Another Label</button>
                    <button onClick={() => handlePrint()}>Print Label</button>
                </div>
            </div>
        </div>
    )
}

const SuccessWithProfiler = Sentry.withProfiler(Success);
export default SuccessWithProfiler;