import React from 'react'
import ReactDOM from 'react-dom/client'
import Layout from './Layout.jsx'
import Index from './pages/Index.jsx'
import './index.css'
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from "react-router-dom";
import Error from './pages/Error.jsx'
import Address from './pages/Address.jsx'
import Package from './pages/Package.jsx'
import PackageDetails from './pages/PackageDetails.jsx'
import ShippingMethod from './pages/ShippingMethod.jsx'
import Submit from './pages/Submit.jsx'
import Success from './pages/Success.jsx'
import { ErrorProvider } from './contexts/ErrorContext.jsx'
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

Sentry.init({
  dsn: "https://ccfc02eda8abf12d3c22e927a7caa111@o4505862152519680.ingest.sentry.io/4505964806733824",
  tunnel: import.meta.env.VITE_SENTRY_TUNNEL_URL,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
        history
      ),
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: false
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: .10, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event, hint) {
    if (hint && hint.originalException && hint.originalException.isAxiosError) {
      if (hint.originalException.response && hint.originalException.response.data) {
        let contexts = {
          ...event.contexts
        };
        contexts.errorResponse = {
          data: hint.originalException.response.data
        };
        event.contexts = contexts;
      }
    }
    return event;
  },
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Layout><Index /></Layout>,
    errorElement: <Error />
  },
  {
    path: "/address",
    element: <Layout><Address /></Layout>,
    errorElement: <Error />
  },
  {
    path: "/package",
    element: <Layout><Package /></Layout>,
    errorElement: <Error />
  },
  {
    path: "/package_details",
    element: <Layout><PackageDetails /></Layout>,
    errorElement: <Error />
  },
  {
    path: "/shipping_method",
    element: <Layout><ShippingMethod /></Layout>,
    errorElement: <Error />
  },
  {
    path: "/submit",
    element: <Layout><Submit /></Layout>,
    errorElement: <Error />
  },
  {
    path: "/success",
    element: <Layout><Success /></Layout>,
    errorElement: <Error />
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ErrorProvider>
      <Sentry.ErrorBoundary fallback={<Error />}>
        <RouterProvider router={router} />
      </Sentry.ErrorBoundary>
    </ErrorProvider>
  </React.StrictMode>,
)
