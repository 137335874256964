import { useState } from 'react';
import Logo from '../icons/Logo';
import ShippingBox from '../icons/ShippingBox';
import Phone from '../icons/Phone';
import { Link } from 'react-router-dom';
import Hamburger from '../icons/Hamburger';
import Slideout from './Slideout';

function Header() {
    const [showSlideout, setShowSlideout] = useState(false);

    return (
        <>
            {/* Desktop */}
            <div className={`hidden md:flex sticky top-0 h-16 px-10 items-center bg-white z-10 drop-shadow-sm w-[calc(100%+var(--removed-body-scroll-bar-size,0px))]`}>
                <div className='w-1/3 flex justify-start'>
                    <a href='https://miravistavets.com/resources/order/' className='block' target="_blank" rel="noreferrer">
                        <div className='flex items-center hover:underline hover:underline-offset-2 decoration-orange-500'>
                            <ShippingBox className={"mr-2"} />
                            <p className='font-semibold text-orange-500 whitespace-nowrap'>Shipping Info</p>
                        </div>
                    </a>
                </div>
                <div className='w-1/3 overflow-clip flex justify-center'>
                    <Link to={'/'}><Logo className={"scale-[70%]"} /></Link>
                </div>
                <div className='w-1/3 flex justify-end'>
                    <Phone className={'mr-2'} />
                    <p className='font-semibold text-orange-500 whitespace-nowrap'>(866) 647-2847 (Option 1)</p>
                </div>
            </div>

            {/* Mobile */}
            <div className={`flex md:hidden sticky top-0 h-16 px-10 items-center justify-between bg-white z-10 drop-shadow-sm w-[calc(100%+var(--removed-body-scroll-bar-size,0px))]`}>
                <span onClick={() => setShowSlideout(!showSlideout)}><Hamburger className={'fill-[#425460] cursor-pointer'} /></span>
                <div className='w-fit overflow-clip flex justify-center'>
                    <Link to={'/'}><Logo className={"scale-[70%] -mt-2"} /></Link>
                </div>
                <Hamburger className={'invisible'} />
            </div>

            {/* Slideout */}
            <Slideout open={showSlideout} setOpen={setShowSlideout} />
        </>
    )
}

export default Header