import useError from "./useError";

export default function useShipmentData() {
    const { setErrorMessage } = useError();

    function setShipment(data) {
        try {
            sessionStorage.setItem("shipment", JSON.stringify(data));
        }
        catch (error) {
            setErrorMessage(error, "useShipmentData");
        }
    }

    function shipment() {
        try {
            return JSON.parse(sessionStorage.getItem("shipment"));
        }
        catch (error) {
            setErrorMessage(error, "useShipmentData");
        }
    }

    return [shipment(), setShipment];
}
