import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import ShippingDetails from '../components/ShippingDetails';
import axios from 'axios';
import useAddressData from '../hooks/useAddressData';
import useQuoteData from '../hooks/useQuoteData';
import Loading from '../components/Loading';
import DatePicker from '../components/DatePicker';
import { addDays, subDays } from '../scripts/DateManipulation';
import useError from '../hooks/useError';
import ErrorAlert from '../components/ErrorAlert';
import * as Sentry from '@sentry/react';
import Tooltip from '../components/Tooltip';
import Question from '../icons/Question.jsx';
import AlertDialog from '../components/AlertDialog.jsx';

function ShippingMethod() {
    const location = useLocation();
    const navigate = useNavigate();
    const { setErrorMessage } = useError();
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [shippingMethod, setShippingMethod] = useState(null);
    const [pickupMethod, setPickupMethod] = useState(null);
    const [shippingDate, setShippingDate] = useState(null);
    const [showDetails, setShowDetails] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [quoteData, setQuoteData] = useState(null);
    const [formDataPrev, setFormDataPrev] = useState(location.state?.formData);

    // Confirmation Dialog
    const [confDialogOpen, setConfDialogOpen] = useState(false);
    const [confDialogResolve, setConfDialogResolve] = useState(null); // For handling promise resolution

    const [address] = useAddressData();
    const [quote, setQuote] = useQuoteData();

    const requiredParams = ['length', 'width', 'height', 'weight', 'declaredValue', 'package_type'];

    const pickupMethodNames = {
        "USE_SCHEDULED_PICKUP": "Scheduled/Recurring Pickup",
        "DROPOFF_AT_FEDEX_LOCATION": "Drop Off at a FedEx Location",
        "CONTACT_FEDEX_TO_SCHEDULE": "Contact FedEx to Schedule a Pickup"
    }

    // Error handling for missing parameters
    useEffect(() => {
        if (Object.keys(formDataPrev).length === 0) {
            setErrorMessage("No parameters were supplied. You have been redirected to the homepage. Please proceed through each page again.", "ShippingMethod");
            navigate('/');
            return;
        }

        for (let i of requiredParams) {
            const bool = Object.keys(formDataPrev).find((val) => val === i)
            if (i === "height" && formDataPrev["package_type"] === "FEDEX_PAK") {
                break;
            }
            if (bool == undefined) {
                setErrorMessage(<>Parameter <span className="font-semibold">{i.toUpperCase()}</span> does not exist. You have been redirected to the homepage. Please proceed through each page again.</>, "ShippingMethod");
                navigate('/');
                return;
            }
        }
    }, [navigate, formDataPrev])


    // Package Details Show/Hide and Quote Data Retrieval
    useEffect(() => {
        // Show/Hide and Loading for Package Details Pane
        if (shippingMethod && shippingDate && pickupMethod) {
            // Debounce call to prevent duplicates being made in quick succession
            // const getQuoteDeb = debounce(getQuote, 500);
            // getQuoteDeb();

            setSubmitDisabled(true); // Always set Submit to disabled while retreiving new quote data
            getQuote();
        }
        else {
            setSubmitDisabled(true);
            setShowDetails(false);
        }



        async function getQuote() {
            setDetailsLoading(true);

            if (parseFloat(formDataPrev['weight']) > 5) {
                setErrorMessage("The weight sent to FedEx was greater than 5 lbs. The maximum allowed weight is 5 lbs. Please go back and change your weight to a valid value.", "ShippingMethod");
                setDetailsLoading(false);
                return;
            }

            const payload = {
                quoteData: {
                    address: {
                        stateOrProvinceCode: address.state,
                        postalCode: address.postalCode,
                    },
                    shipDateStamp: shippingDate.toISOString().substring(0, 10),
                    serviceType: shippingMethod,
                    pickupType: pickupMethod,
                    packagingType: formDataPrev['package_type'],
                    requestedPackageLineItems: [
                        {
                            weight: {
                                value: parseFloat(formDataPrev['weight']),
                                units: "LB",
                            },
                            dimensions: {
                                length: parseFloat(formDataPrev['length']),
                                width: parseFloat(formDataPrev['width']),
                                height: formDataPrev['package_type'] === "FEDEX_PAK" ? 0 : parseFloat(formDataPrev['height']),
                                units: "IN"
                            }
                        }
                    ]
                }
            }

            axios.post(`${import.meta.env.VITE_BASE_API_URL}/quotes`, payload)
                .then((res) => {
                    setQuoteData({...res.data.token.output.rateReplyDetails[0], pickupMethod: {name: pickupMethodNames[pickupMethod], code: pickupMethod}});
                    setShowDetails(true);
                    setSubmitDisabled(false);
                })
                .catch((error) => {
                    if (error.response.data.showUser) {
                        setErrorMessage(error.response.data.message, "ShippingMethod");
                    }
                    else {
                        setErrorMessage(error, "ShippingMethod");
                    }
                    setSubmitDisabled(true);
                })
                .finally(() => {
                    setDetailsLoading(false);
                })
        }
    }, [address.postalCode, address.state, formDataPrev, shippingDate, shippingMethod, pickupMethod])

    function handleInputChange(e) {
        if (e.target.name === "shippingMethod") {
            setShippingMethod(e.target.value);
        }
        else if (e.target.name === "shippingDate") {
            const future = new Date();
            future.setDate(future.getDate() + 10)
        }
        else if (e.target.name === "pickupMethod") {
            setPickupMethod(e.target.value);
        }
    }

    function promptForConfirmation() {
        setConfDialogOpen(true);
        return new Promise((resolve, reject) => {
            setConfDialogResolve({ resolve, reject });
        });
    }

    function handleDialogAction() {
        setConfDialogOpen(false);
        if (confDialogResolve) {
            confDialogResolve.resolve();
        }
    }

    function handleDialogCancel() {
        setConfDialogOpen(false);
        if (confDialogResolve) {
            confDialogResolve.reject();
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();

        // Alert user if they chose to contact FedEx to arrange pickup
        //// Pickup Fee
        //// Not auto scheduled
        //// Remind them to reach out to FedEx
        if (pickupMethod === "CONTACT_FEDEX_TO_SCHEDULE") {
            try {
                await promptForConfirmation();
            } catch {
                return; // If the user cancels, exit the function
            }
        }
        

        // Set quote data in sessionStorage
        let quote = { ...quoteData };
        quote['weight'] = parseFloat(formDataPrev['weight']);
        quote['shipmentDate'] = shippingDate;
        quote['declaredValue'] = parseFloat(formDataPrev['declaredValue']);

        setQuote(quote);

        navigate('/submit');
    }

    return (
        <>
            <AlertDialog
                open={confDialogOpen}
                setOpen={setConfDialogOpen} 
                title={'FedEx Contact Required'} 
                description={'You selected the option to contact FedEx to schedule a pickup. You must contact them after the label has been created to arrange a pickup.'}
                cancelButton={{enabled: true, text: "Cancel", action: handleDialogCancel}}
                actionButton={{enabled: true, text: "Continue", action: handleDialogAction}}
            >
                <p className='font-semibold'>Note: FedEx may charge a fee for one-time pickups. See <a href='https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html#cost' target="_blank" rel="noreferrer">this page</a> for more information.</p>
                <p>Would you like to continue?</p>
            </AlertDialog>
            <ErrorAlert />
            <div className='flex flex-col gap-y-5'>
                <div>
                    <h1 className='text-xl font-semibold mb-2'>Select a shipping date and method.</h1>
                    <p className=''>Your discounted shipping cost will be included in your invoice.</p>
                </div>

                <div className='flex'>
                    <form className='flex flex-col w-full gap-y-10' onChange={(e) => handleInputChange(e)} onSubmit={(e) => handleSubmit(e)}>
                        <div className='flex flex-col xl:flex-row justify-between gap-y-14'>
                            {/* Inputs */}
                            <div className='flex flex-col gap-y-5'>
                                {/* Date */}
                                <div className='flex flex-col w-fit'>
                                    <label htmlFor='shippingDate'>Ship Date <span className='text-red-500'>*</span></label>
                                    <DatePicker selected={shippingDate} setSelected={setShippingDate} includeDates={{ start: subDays(new Date(), 1), end: addDays(new Date(), 10) }} />
                                </div>

                                {/* Shipping Method */}
                                <div className='flex flex-col w-fit items-start'>
                                    <p className='font-semibold mb-2'>Select a shipping option to see the price and delivery details. <span className='text-red-500'>*</span></p>
                                    <div>
                                        <input className='mr-2' type="radio" name="shippingMethod" id="priority_overnight" value="PRIORITY_OVERNIGHT" onChange={(e) => setShippingMethod(e.target.value)} />
                                        <label className='font-normal' htmlFor='priority_overnight'>FedEx Priority Overnight®</label>
                                    </div>
                                    <div>
                                        <input className='mr-2' type="radio" name="shippingMethod" id="standard_overnight" value="STANDARD_OVERNIGHT" onChange={(e) => setShippingMethod(e.target.value)} />
                                        <label className='font-normal' htmlFor='standard_overnight'>FedEx Standard Overnight®</label>
                                    </div>
                                    <div>
                                        <input className='mr-2' type="radio" name="shippingMethod" id="2day" value="FEDEX_2_DAY" onChange={(e) => setShippingMethod(e.target.value)} />
                                        <label className='font-normal' htmlFor='2day'>FedEx 2Day®</label>
                                    </div>
                                    <div className='flex items-center '>
                                        <input className='mr-2' type="radio" name="shippingMethod" id="ground" value="FEDEX_GROUND" disabled={formDataPrev['package_type'] != "YOUR_PACKAGING"} onChange={(e) => setShippingMethod(e.target.value)} />
                                        <label className='font-normal mb-0' htmlFor='ground'>FedEx Ground®</label>
                                        {
                                            formDataPrev["package_type"] != "YOUR_PACKAGING" && 
                                            <Tooltip content={"You must use your own packaging to use FedEx Ground."}>
                                                <span><Question width={14} height={14} className='rounded-full bg-orange-500 fill-white mx-1 cursor-pointer' /></span>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>

                                {/* Pickup Type */}
                                <div className='flex flex-col w-fit items-start'>
                                    <p className='font-semibold mb-2'>Select a pickup option. <span className='text-red-500'>*</span></p>
                                    <div>
                                        <input className='mr-2' type="radio" name="pickupMethod" id="scheduled_pickup" value="USE_SCHEDULED_PICKUP" onChange={(e) => setPickupMethod(e.target.value)} />
                                        <label className='font-normal' htmlFor='scheduled_pickup'>Scheduled/Recurring Pickup</label>
                                    </div>
                                    <div>
                                        <input className='mr-2' type="radio" name="pickupMethod" id="dropoff" value="DROPOFF_AT_FEDEX_LOCATION" onChange={(e) => setPickupMethod(e.target.value)} />
                                        <label className='font-normal' htmlFor='dropoff'>Drop-off at a FedEx location</label>
                                    </div>
                                    
                                        <div>
                                            <input className='mr-2' type="radio" name="pickupMethod" id="contact_fedex_to_schedule" value="CONTACT_FEDEX_TO_SCHEDULE" onChange={(e) => setPickupMethod(e.target.value)} />
                                            <label className='font-normal' htmlFor='contact_fedex_to_schedule'>I will contact FedEx to schedule a pickup</label>
                                        </div>
                                </div>
                            </div>


                            {
                                shippingDate && shippingMethod && pickupMethod && detailsLoading ?
                                    <div className={`flex relative border-4 border-orange-500 rounded-md p-5 max-w-[36rem] xl:w-[36rem] h-[274px]`}>
                                        <Loading color={'orange'} className={'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'} />
                                    </div>
                                    :
                                    showDetails && quoteData &&
                                    <div className={`flex relative border-4 border-orange-500 rounded-md p-5 max-w-[36rem]`}>
                                        <ShippingDetails data={{ ...quoteData, weight: formDataPrev['weight'] }} />
                                    </div>
                            }
                        </div>
                        <div className='flex justify-end gap-x-2'>
                            <button type='button' className={`button`} onClick={() => navigate(-1)}>Back</button>
                            <button type='submit' className={`button self-end ${submitDisabled && '!bg-neutral-400 hover:shadow-none !cursor-default'}`} disabled={submitDisabled}>Next</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

const ShippingMethodWithProfiler = Sentry.withProfiler(ShippingMethod);
export default ShippingMethodWithProfiler;